export const sortByKey = props => {
  if (props.itemsArray?.length > 1) {
    const sortedArray = props.itemsArray.sort((previous, current) => {
      /* eslint no-eval: 0 */
      const previousItem = props.nestedKey
        ? eval("previous" + props.nestedKey)
        : previous
      const currentItem = props.nestedKey
        ? eval("current" + props.nestedKey)
        : current

      return previousItem < currentItem
        ? -1
        : previousItem > currentItem
        ? 1
        : 0
    })
    // Sort ascending
    if (
      props.sortDirection &&
      new RegExp("asc".toUpperCase()).test(props.sortDirection.toUpperCase())
    ) {
      return sortedArray
    }
    // Sort descending
    if (
      props.sortDirection &&
      new RegExp("desc".toUpperCase()).test(props.sortDirection.toUpperCase())
    ) {
      return sortedArray.reverse()
    }
    // Sort ascending by default
    return sortedArray
  }

  return props.itemsArray
}

export const sortRandom = props => {
  if (props.itemsArray?.length > 1) {
    let currentIndex = props.itemsArray.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = props.itemsArray[currentIndex]
      props.itemsArray[currentIndex] = props.itemsArray[randomIndex]
      props.itemsArray[randomIndex] = temporaryValue
    }
  }

  return props.itemsArray
}
