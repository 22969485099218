export const excludePages = [
  "/",
  "/404/",
  "/404.html",
  "/dev-404-page/",
  "/build/",
  "/demo/",
  "/graphql/",
  "/preview/",
]
