import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Typography } from "@material-ui/core"

import Section from "../sections/section"
import CardBlock from "../blocks/card"
import CustomLink from "../modules/custom-link"

import { sortByKey } from "../functions/sort-array"

import { excludePages } from "../data/settings"

const NotFound = () => {
  // React.useEffect(() => {
  //   console.log("=== 404 Page ===")
  // })

  const data = useStaticQuery(graphql`
    query {
      allSitePage {
        nodes {
          id
          path
        }
      }
    }
  `)

  const pages = sortByKey({
    itemsArray: data.allSitePage.nodes.filter(
      x => !excludePages.includes(x.path)
    ),
    nestedKey: ".path",
    sortDirection: "ASCENDING",
  })

  return (
    <Section background={"LIGHT"} padding={true} height={"SIZE_50"}>
      <CardBlock
        width={"SIZE_100"}
        background={"LIGHT"}
        transparent={true}
        center={true}
      >
        <Typography variant="h3" gutterBottom>
          Pages accessibles
        </Typography>
        <Typography>Ce site contient {pages.length} pages :</Typography>
        <ul>
          {pages.map(x => (
            <li key={x.id}>
              <CustomLink url={x.path} style={{ color: "" }}>
                <b>{x.path}</b>
              </CustomLink>
            </li>
          ))}
        </ul>
      </CardBlock>
    </Section>
  )
}

export default NotFound
